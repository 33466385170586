.slick-prev,
.slick-next {
  background: transparent !important;
  box-shadow: none;
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  color: black;
}
.slick-prev {
  float: left;
  /* margin-left: 20px; */
}
.slick-next {
  float: right;

  /* margin-right: 20px; */
}
div:focus {
  outline: none;
}
.slick-prev:before,
.slick-next:before {
  content: "";
  height: "100%";
}
.slick-prev:hover,
.slick-next:hover,
.slick-next:focus,
.slick-prev:focus {
  color: #000;
}
.vegetableItemWrapper {
  display: flex !important;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 300px;
}
.vegetablesDataContainer {
  margin: 0 3rem;
  margin-bottom: 80px;
}
.arrowBtns {
  display: flex;
  justify-content: center;
  align-items: center;
  height: "90px";
  background-color: red;
}

@media only screen and (max-width: 900px) {
  .vegetablesDataContainer {
    margin-bottom: 40px;
  }
  .slick-prev {
    float: left;
    margin-left: 20px;
  }
  .slick-next {
    float: right;

    margin-right: 20px;
  }
}
